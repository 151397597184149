import { cloneDeep } from 'lodash';
import * as types from '../actions/types';
import { path } from 'ramda';

const INITIAL_LASTEST_USER_OPTIONS = {
  age: null,
  body_type: null,
  dating_time: null,
  face_preferences: null,
  dating_area: null
};

const INITIAL = {
  data: null,
  participationItemsData: null,
  userParticipationItems: null,
  participationItemList: null,
  needToPurchaseItems: null,
  needToPurchaseItemsPrice: null,
  userOwnedParticipationItems: null,
  participationDetail: null,
  appliedItems: null,
  isError: false,
  lastestUserOptions: INITIAL_LASTEST_USER_OPTIONS,
  isInit: false
};

const userParticipationItemsReducer = (state = INITIAL, action) => {
  switch (action.type) {
    case types.USER_PARTICIPATION_ITEMS_REQUESTED:
      return {
        ...state,
        error: null
      };
    case types.USER_PARTICIPATION_ITEMS_SUCCEEDED: {
      const participationDetail = path(
        ['data', 'data', 'participationDetail'],
        action
      );
      const participationItemsData = path(
        ['data', 'data', 'participationItems'],
        action
      );
      const originalUserParticipationItems = path(
        ['data', 'data', 'userItems'],
        action
      );
      const userParticipationItemsForEditing = cloneDeep(
        originalUserParticipationItems
      );
      Object.keys(participationItemsData).forEach(key => {
        if (participationItemsData[key].is_applied_items) {
          if (userParticipationItemsForEditing.hasOwnProperty(key)) {
            userParticipationItemsForEditing[key].remainItem += 1;
          } else {
            userParticipationItemsForEditing[key] = {
              ...participationItemsData[key],
              remainItem: 1
            };
          }
        }
      });
      const userParticipationItems = participationDetail.is_editing
        ? userParticipationItemsForEditing
        : originalUserParticipationItems;
      return {
        ...state,
        participationItemsData: participationItemsData,
        userParticipationItems: userParticipationItems,
        participationItemList: path(['data', 'data', 'itemList'], action),
        needToPurchaseItems: path(['data', 'data', 'purchaseItems'], action),
        needToPurchaseItemsPrice: path(['data', 'data', 'totalPrice'], action),
        participationDetail: participationDetail,
        appliedItems: path(['data', 'data', 'appliedItems'], action),
        data: action.data.data,
        isInit: true,
        error: null
      };
    }
    case types.USER_PARTICIPATION_ITEMS_FAILED:
      return {
        ...state,
        data: null,
        isError: true,
        error: action.error
      };
    case types.UPDATE_USER_PARTICIPATION_ITEMS_REQUESTED:
      return {
        ...state,
        error: null
      };
    case types.UPDATE_USER_PARTICIPATION_ITEMS_FAILED:
      return {
        ...state,
        isError: true,
        error: action.error
      };
    case types.USER_OWNED_PARTICIPATION_ITEMS_REQUESTED:
      return {
        ...state,
        error: null
      };
    case types.USER_OWNED_PARTICIPATION_ITEMS_SUCCEEDED:
      return {
        ...state,
        userOwnedParticipationItems: action.data.data,
        error: null
      };
    case types.USER_OWNED_PARTICIPATION_ITEMS_FAILED:
      return {
        ...state,
        data: null,
        isError: true,
        error: action.error
      };
    case types.UPDATE_USER_PARTICIPATION_ITEMS: {
      const tempLastestUserOptions = { ...state.lastestUserOptions };
      tempLastestUserOptions[action.name] = action.value;
      return {
        ...state,
        lastestUserOptions: tempLastestUserOptions
      };
    }
    case types.DELETE_USER_PARTICIPATION_ITEM: {
      const tempLastestUserOptions = { ...state.lastestUserOptions };
      tempLastestUserOptions[action.name] = null;
      return {
        ...state,
        lastestUserOptions: tempLastestUserOptions
      };
    }
    case types.CLEAR_LASTEST_PARTICIPATION_ITEMS_DATA:
      return {
        ...state,
        lastestUserOptions: INITIAL_LASTEST_USER_OPTIONS,
        isInit: false
      };
    default:
      return state;
  }
};

export default userParticipationItemsReducer;
