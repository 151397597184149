import React from 'react';
import PropTypes from 'prop-types';
import { NavBar } from 'antd-mobile';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { path } from 'ramda';

import styles from './index.module.css';
import { ReactComponent as MoonIcon } from '@assets/common/moon.svg';
import { ReactComponent as SunIcon } from '@assets/common/sun.svg';
import { ReactComponent as ArrowLeft } from '@assets/newSystem/squareItem/svg/arrow_left.svg';

class NavBarMain extends React.PureComponent {
  state = {};

    renderArrowLeft = () => {
      const {onClickBack, hasWhiteHeader, backButtonColor} = this.props;
      const strokeColor = backButtonColor || (hasWhiteHeader ? 'var(--black)' : 'var(--white)');
      return (
        <ArrowLeft
          onClick={onClickBack}
          style={{ stroke: strokeColor}}
        />
       );
    };
  
  render() {
    const {
      userAvatar,
      isLunchTime,
      dateTimeText,
      isShowArrowLeft,
      hasWhiteHeader,
    } = this.props;

    if (userAvatar) {
      return (
        <>
          <NavBar
            className={classNames(styles.navBar, this.props.customClassName, {
              [styles.navBarWhite]: hasWhiteHeader
            })}
            leftContent={
              isShowArrowLeft ? this.renderArrowLeft() : this.props.leftContent
            }
            rightContent={this.props.rightContent}
            onLeftClick={this.props.onLeftClick}
          >
            <div className={styles.chatNavTitle}>
              <div
                className={classNames(styles.navNickname, {
                  [styles.textBlack]: hasWhiteHeader
                })}
              >
                <span
                  style={{
                    marginRight: '5px'
                  }}
                >
                  {isLunchTime ? <SunIcon /> : <MoonIcon />}
                </span>
                {dateTimeText}
              </div>
            </div>
          </NavBar>
          {this.props.chatHeader}
        </>
      );
    }

    if (this.props.innerRef) {
      return (
        <div ref={this.props.innerRef}>
          <NavBar
            className={classNames(styles.navBar, this.props.customClassName, {
              [styles.navBarWhite]: hasWhiteHeader
            })}
            leftContent={
              isShowArrowLeft ? this.renderArrowLeft() : this.props.leftContent
            }
            rightContent={this.props.rightContent}
            onLeftClick={this.props.onLeftClick}
          >
            {this.props.title}
          </NavBar>
          {this.props.chatHeader}
        </div>
      );
    }

    return (
      <>
        <NavBar
          className={classNames(styles.navBar, this.props.customClassName, {
            [styles.navBarWhite]: hasWhiteHeader
          })}
          leftContent={
            isShowArrowLeft ? this.renderArrowLeft() : this.props.leftContent
          }
          rightContent={this.props.rightContent}
          onLeftClick={this.props.onLeftClick}
        >
          {this.props.children}
          {this.props.title}
        </NavBar>
        {this.props.chatHeader}
      </>
    );
  }
}

NavBarMain.propTypes = {
  title: PropTypes.string,
  rightContent: PropTypes.array,
  leftContent: PropTypes.array,
  icon: PropTypes.element,
  onLeftClick: PropTypes.func,
  onUserAvatarClick: PropTypes.func,
  customClassName: PropTypes.string,
  titleClassName: PropTypes.string,
};

NavBarMain.defaultProps = {
  title: '',
  rightContent: [],
  leftContent: [],
  icon: null,
  onLeftClick: () => {},
  customClassName: undefined,
};

const mapStateToProps = state => {
  return {
    hasWhiteHeader: path(['userGlobalInfo', 'data', 'has_white_header'], state)
  };
};

export default connect(mapStateToProps, null)(NavBarMain);
export { default as BackArrowIcon } from './BackArrowIcon';
