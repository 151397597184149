import { call, put, takeLatest } from 'redux-saga/effects';
import * as types from '../actions/types';
import { requestAPI } from '../services/request';
import { API } from '../services/api';
import { ResponseStatus } from '../../constants/constant';
import { store } from '../store';
import { hideLoading, showLoading } from '../actions';

function* getUserParticipationItems(action) {
  let response = null;
  store.dispatch(showLoading());
  try {
    response = yield call(
      requestAPI,
      API.GET_PARTICIPATION_ITEMS,
      API.GET,
      action.params
    );

    if (response.status === ResponseStatus.SUCCESS) {
      yield put({
        type: types.USER_PARTICIPATION_ITEMS_SUCCEEDED,
        data: response.data
      });
    } else {
      yield put({
        type: types.USER_PARTICIPATION_ITEMS_FAILED,
        error: response
      });
    }
    if (action.onComplete) {
      action.onComplete(response);
    }
  } catch (error) {
    yield put({ type: types.USER_PARTICIPATION_ITEMS_FAILED, error });
  }
  store.dispatch(hideLoading());
}


function* updateUserParticipationItemsStatus(action) {
  let response = null;
  store.dispatch(showLoading());
  try {
    response = yield call(
      requestAPI,
      API.UPDATE_USER_PARTICIPATION_ITEMS_STATUS,
      API.POST,
      action.params
    );

    if (response.status === ResponseStatus.SUCCESS) {
      yield put({
        type: types.UPDATE_USER_PARTICIPATION_ITEMS_STATUS_SUCCEEDED
      });
    } else {
      yield put({
        type: types.UPDATE_USER_PARTICIPATION_ITEMS_STATUS_FAILED,
        error: response
      });
    }
    if (action.onComplete) {
      action.onComplete(response);
    }
  } catch (error) {
    yield put({ type: types.UPDATE_USER_PARTICIPATION_ITEMS_STATUS_FAILED, error });
  }
  store.dispatch(hideLoading());
}

function* purchaseDatingItems(action) {
  let response = null;
  store.dispatch(showLoading());
  try {
    response = yield call(
      requestAPI,
      API.PURCHASE_DATING_ITEMS,
      API.POST,
      action.params
    );

    if (response.status === ResponseStatus.SUCCESS) {
      yield put({
        type: types.PURCHASE_DATING_ITEMS_SUCCEEDED
      });
    } else {
      yield put({
        type: types.PURCHASE_DATING_ITEMS_FAILED,
        error: response
      });
    }
    if (action.onComplete) {
      action.onComplete(response);
    }
  } catch (error) {
    yield put({ type: types.PURCHASE_DATING_ITEMS_FAILED, error });
  }
  setTimeout(() => {
    store.dispatch(hideLoading());
  }, 1500)
}

function* getUserOwnedParticipationItems(action) {
  let response = null;
  store.dispatch(showLoading());
  try {
    response = yield call(
      requestAPI,
      API.GET_USER_PARTICIPATION_ITEMS,
      API.GET,
      action.params
    );

    if (response.status === ResponseStatus.SUCCESS) {
      yield put({
        type: types.USER_OWNED_PARTICIPATION_ITEMS_SUCCEEDED,
        data: response.data
      });
    } else {
      yield put({
        type: types.USER_OWNED_PARTICIPATION_ITEMS_FAILED,
        error: response
      });
    }
    if (action.onComplete) {
      action.onComplete(response);
    }
  } catch (error) {
    yield put({ type: types.USER_OWNED_PARTICIPATION_ITEMS_FAILED, error });
  }
  store.dispatch(hideLoading());
}

export function* watchGetUserParticipationItems() {
  yield takeLatest(
    types.USER_PARTICIPATION_ITEMS_REQUESTED,
    getUserParticipationItems
  );
}

export function* watchUpdateUserParticipationItemsStatus() {
  yield takeLatest(
    types.UPDATE_USER_PARTICIPATION_ITEMS_STATUS_REQUESTED,
    updateUserParticipationItemsStatus
  );
}

export function* watchPurchaseDatingItems() {
  yield takeLatest(types.PURCHASE_DATING_ITEMS_REQUESTED, purchaseDatingItems);
}

export function* watchGetUserOwnedParticipationItems() {
  yield takeLatest(
    types.USER_OWNED_PARTICIPATION_ITEMS_REQUESTED,
    getUserOwnedParticipationItems
  );
}
