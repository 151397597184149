import { API } from '../redux/services/api';
import {
  SUPPORT_EMAIL_SETTING_LINK,
  NEW_NEW_FLOW_FEMALE_LINK,
  SUPPORT_BACHELOR_FE_LINK,
  VIDEO_DATE_SUGGESTION,
  SUPPORT_BACHELOR_MA_LINK
} from './helpLink';

export const HttpCode = {
  SUCCESS: 200,
  UNAUTHORIZED: 401
};

export const StorageKey = {
  SOCIAL_ID: '5LOs0kIiq6M5upU',
  LOGIN_TYPE: 'KyDey0IBFZdG6PL',
  LOGIN_MIGRATING: 'uEvxKOFyfpVVxPF',
  REOPEN: 'REOPEN'
};

export const LpQueryStr = {
  LOCAL_STORAGE_KEY: 'lp_query_str'
};

export const PaymentGateway = {
  STRIPE: 'stripe',
  APPLE_PAY: 'apple_pay'
};

export const MonthData = [
  { value: '1', label: 1 },
  { value: '2', label: 2 },
  { value: '3', label: 3 },
  { value: '4', label: 4 },
  { value: '5', label: 5 },
  { value: '6', label: 6 },
  { value: '7', label: 7 },
  { value: '8', label: 8 },
  { value: '9', label: 9 },
  { value: '10', label: 10 },
  { value: '11', label: 11 },
  { value: '12', label: 12 }
];

export const Weeks = {
  THIS_WEEK: 'THIS_WEEK',
  NEXT_WEEK: 'NEXT_WEEK',
  NEXT_NEXT_WEEK: 'NEXT_NEXT_WEEK'
};

export const AuthType = {
  MOBILE: 'MOBILE',
  LINE: 'LINE',
  FACEBOOK: 'FACEBOOK'
};

export const ResponseStatus = {
  FAILURE: 0,
  SUCCESS: 200,
  VALIDATEFAIL: 422,
  // SUCCESS: 1,
  FORCEFULLY: 9,
  NEW_USER: 8,
  DEACTIVE_USER: 5,
  PROFILE_AWAITING_USER: 2,
  INCOMPLETE_PROFILE_USER: 1,
  DOCCUMENT_REQUIRED: 10,
  CANCEL_USER: 6,
  TEMPORARY_CANCEL: 11,
  PERMANENT_CANCEL: 12,
  LOCK_USER: 7,
  VALIDATION_ERROR: 101,
  FEEDBACK_VIEW: 301,
  COUPON_VALIDATION_UNPARTICIPATION: 12,
  FEEDBACK_NOT_GIVEN: 13,
  PROFILE_MISSING_EMAIL: 14,
  INTERNAL_SERVER_ERROR: 500,
  INVALID_VERIFICATION_CODE: 513,
  DUPLICATE_USER: 515
};

export const UserStatus = {
  INCOMPLETE_PROFILE: 1,
  AWAITING_SCREENING: 2,
  VERIFIED_BY_ADMIN: 3,
  APPROVED_BY_SYSTEM: 4,
  DEACTIVATED: 5,
  CANCELLED_PROFILE: 6
};

export const PreferencesComplete = {
  LAST_STEP_FIRST_FORM: 7,
  LAST_STEP_SECOND_FORM: 16
};

export const ParticipationAvailability = {
  NONE: 'none',
  All: 'all',
  OncePerWeek: 'once_per_week',
  OncePerThreeWeeks: 'once_per_three_weeks'
};

export const WeekPlan = {
  WEEKDAY: 'weekday',
  WEEKEND: 'weekend'
};

export const CostPlan = {
  LIGHT: 'light',
  NORMAL: 'normal',
  PREMIUM: 'premium',
  BLACK: 'black'
};

export const ContractTerm = {
  ONE_MONTH: 1,
  THREE_MONTH: 3,
  SIX_MONTH: 6,
  TWELVE_MONTH: 12
};

export const TrialStatusOrPaid = {
  TRIAL: 'Trial',
  PAID: 'Paid',
  TEMP_CANCELLED: 'TempCancelled'
};

export const TrialStatus = {
  ACTIVE: 10,
  TEMP_CANCELLED: 20,
  COMPLETED: 30
};

export const ParticipantsStatus = {
  Awaiting: 10,
  Unmatched: 20,
  Matched: 30,
  Canceled: 40
};

export const DatingEvent = {
  CANCELLED: 1,
  LOCATION_CHANGED: 2
};

export const ExchangeCoupon = {
  NUMBER_BOTH_DATE_EXCHANGE: 3
};

export const CouponStatus = {
  USED: 1,
  UN_USED: 2,
  EXCHANGED: 4,
  EXPIRED: 5
};

export const CouponType = {
  DATING: 'dating',
  BACHELOR: 'bachelor'
};

export const UserGender = {
  MALE: 1,
  FEMALE: 2,
  BOTH: 3
};

export const DateTimeType = {
  CLEAR_GRAY: 'clear_gray',
  DARK_GRAY: 'dark_gray'
};

export const DESCRIPTION_TEXT = {
  WAITING_MATCHING_ALTHOUGH_MATCHED:'マッチング中',
  WAITING_FOR_MATCHING: 'マッチング待ち',
}
export const DESCRIPTION_TYPE = {
  //incomplete form 2 and show_sample_date = 1
  CONFIRM_BUTTON: 'confirm_button',

  //no dating, show_sample_date = 1
  SAMPLE_DATE: 'sample_date',

  //Set start_at in table dating as same as dating_date in dating_day
  CHAT_BUTTON: 'chat_button',

  //step = 16, no dating, status = 10, show_sample_date = 0, no rematching record
  AWAITING_MATCHING: 'awaiting_matching',

  //step = 16, no dating, status = 20, show_sample_date = 0, has rematching record
  AWAITING_RE_MATCHING: 'awaiting_re_matching',

  AWAITING_RE_MATCHING_FOR_LUNCH: 'awaiting_re_matching_for_lunch',

  //has dating and dating status = 1
  PARTNER_DETAIL: 'partner_detail',

  //dating status = 2 and has rematching record
  PARTNER_CANCELLED: 'partner_cancelled',

  //dating status = 2 and no rematching record
  DATE_CANCELLED: 'date_cancelled',

  UNMATCHED_BEFORE_RE_MATCHING_TIME: 'unmatched_before_rematching_time',
  UNMATCHED_AFTER_RE_MATCHING_TIME: 'unmatched_after_rematching_time',
  DATING_COMPLETED_TODAY: 'dating_completed_today'
};

export const RegistrationFormKey = {
  DRINK: 'drink',
  DIVORCE: 'divorce',
  SMOKE: 'smoke',
  INCOME: 'income',
  LIMIT_MIN_AGE: 18,
  LIMIT_MAX_AGE: 99,
  MIN_RANGE_AFTER_40_NEED_PREMIUM: 6,
  AGE_NEED_TO_CHECK_PREMIUM: 40,
  AGE_NEED_TO_CHECK_REGISTRATION_VERSION: 35,
  HEIGHT_MINIUM_MALE: 160,
  HEIGHT_MINIUM_FEMALE: 170,
  MINIMUM_AGE_DISTANCE: 8,
  MINIMUM_HEIGHT_DISTANCE: 10,
  DEFAULT_HEIGHT_TO_VALUE: 999,
  DEFAULT_HEIGHT_FROM_VALUE: 0,
  DEFAULT_AGE_TO_VALUE: 99,
  DEFAULT_AGE_FROM_VALUE: 0,
  DEFAULT_BODY_SHAPE_TO_VALUE: 99,
  MINIMUM_BODY_SHAPE_DISTANCE: 1,
  MINIMUM_BODY_SHAPE_DISTANCE_NOT_CARE: 2,
  DEFAULT_BODY_SHAPE_FROM_VALUE: 0
};

export const CARD_CONSTANTS = {
  MIN_LENGTH: 14,
  MAX_LENGTH: 16,
  CVC_LENGTH: 3
};

export const ParticipationOpenExpireStatus = {
  CLOSED: 0,
  OPENED: 1,
  EXPIRED: 2
};

export const FeedbackPointType = {
  BAD: 1,
  GOOD: 2
};

export const RECOMMENDATION = {
  AWAITING_CANCEL: 'awaiting_cancel',
  RECOMMEND: 'recommend'
};

export const SUBSCRIPTION_STATUS = {
  Active: 'active',
  Canceled: 'canceled',
  Incomplete: 'incomplete'
};

export const FeedbackType = {
  FACE: '容姿/雰囲気',
  PERSONALITY: '性格・会話',
  BEHAVIOR: '振る舞い'
};

export const MatchDetails = {
  STATUS_NO_ANY_DATE: 0,
  STATUS_DATING_MATCHED_CONFIRM: 1,
  STATUS_DATING_MATCHED_CANCELLED: 2,
  STATUS_DATING_MATCHED_COMPLETED: 3
};

export const RequestChangeTime = {
  STATUS_REQUEST_TIME_PENDING_DELAY: 0,
  STATUS_REQUEST_TIME_PENDING: 1,
  STATUS_REQUEST_TIME_CANCEL: 2,
  STATUS_REQUEST_TIME_REJECT: 3,
  STATUS_REQUEST_TIME_APPROVED: 4
};

export const MatchProfile = {
  POPUP_PARTNER_NOT_FOUND: 1,
  POPUP_HOW_TO_BE_MATCHED: 2,
  POPUP_DATE_DECIDED: 3,
  POPUP_PARTNER_CANCELLED: 4,
  POPUP_PARTNER_CHANGED_LOCATION: 5
};

export const GlobalPopup = {
  CHANGE_COST_PLAN: 6,
  CHANGE_COST_PLAN_DOWNGRADE: 7,
  CHANGE_COST_PLAN_UPGRADE: 8,
  COMPLETE_UPDATE_PROFILE: 9
};

export const CancelDateByMeOption = {
  // Constants for cancel option in cancelled date by me api
  INFORMED_PARTNER: 1,
  DID_NOT_SHOW_UP_PARTNER: 2
};

export const CancelDateByPartnerOption = {
  INFORMED_ME: 1,
  DID_NOT_SHOW_UP: 2
};

export const ChatFeature = {
  USER_TYPE_BASE_USER: 'baseUser',
  USER_TYPE_PARTNER_USER: 'partnerUser',
  USER_TYPE_CAFE_INFO: 'cafeInfo',
  USER_TYPE_CAFE_SUGGESTION: 'cafeSuggestion',
  USER_TYPE_PARK_SUGGESTION: 'parkSuggestion',
  USER_TYPE_VIDEO_SUGGESTION: 'videoSuggestion',
  USER_TYPE_CAFE_SUGGESTION_LINK: 'cafeSuggestionLink',
  USER_TYPE_VIDEO_SUGGESTION_LINK: 'videoSuggestionLink',
  LOCATION_CATEGORY_CAFE: 'cafe',
  LOCATION_CATEGORY_PARK: 'park',
  USER_TYPE_CHANGE_TIME_SUGGESTION: 'changeTimeSuggestion'
};
export const redirectVideoDateSuggestion = userGender => {
  const link =
    userGender === UserGender.FEMALE
      ? VIDEO_DATE_SUGGESTION.FEMALE
      : VIDEO_DATE_SUGGESTION.MALE;
  window.open(link);
};

export const ErrorFeedBack = {
  FACE: 'face',
  FACE_GOOD: 'face_good',
  FACE_BAD: 'face_bad',
  PERSONALITY: 'personality',
  PERSONALITY_GOOD: 'personalityGood',
  PERSONALITY_BAD: 'personalityBad',
  BEHAVIOR_GOOD: 'behavior_good',
  BEHAVIOR: 'behavior',
  BODY_SHAPE: 'body_shape',
  BACHELOR_SUITABLE: 'bachelor-suitable',
  ABOUT_NEXT_TIME: 'about-next-time',
  SATISFACTION: 'satisfaction',
  BEHAVIOR_BAD: 'behavior_bad',
  FLEX_OPTIONS: 'flex_options',
  ABLE_TO_USE_CAFE: 'able_to_use_cafe',
  CAFE_REVIEW: 'cafe_review',
  ANY_COMPLAINT: 'any_complaint',
  USE_ANOTHER_CAFE: 'use_another_cafe',
  REASON_FOR_CHANGING_CAFE: 'reason_for_changing_cafe',
  THE_BETTER_POINT: 'the_better_point',
  THE_WORSE_POINT: 'the_worse_point',
  COMMENT_SOMETHING_DIFFERENT: 'comment_something_different'
};

export const redirectFB = () => {
  window.location.replace(API.BASE_URL + 'social-login?redirect_url=app-login');
};
export const redirectReopenForm = () => {
  window.location.replace(API.BASE_URL + 'reopen-form');
};
export const redirectSmsLogin = nextAction => {
  window.location.replace(
    API.BASE_URL +
      `sms-portal?redirect_url=app-login${
        nextAction ? '&action=' + nextAction : ''
      }`
  );
};
export const redirectUserProfile = () => {
  window.location.replace(API.BASE_URL + 'user-profile');
};
export const redirectUserProfileStep2 = () => {
  window.location.replace(API.BASE_URL + 'user-profile?edit=1&step=2');
};
export const redirectLINELogin = () => {
  window.location.replace(API.BASE_URL + `api/v2/auth/login/line`);
};
export const redirectFacebookLogin = () => {
  window.location.replace(API.BASE_URL + `api/v2/auth/login/facebook`);
};
export const redirectLINE = (socialLoginLink, userId) => {
  window.location.replace(
    `${socialLoginLink}&id=${userId}&redirect_url=${encodeURIComponent(
      API.BASE_URL + 'social-login?redirect_url=line-login'
    )}`
  );
};

export const redirectUnderScreeningMaleUser = () => {
  window.location.replace('https://wi.bachelorapp.net/usc-male');
};

export const redirectSMS = (userId, email) => {
  window.location.replace(
    `https://bachelorapp.zendesk.com/hc/ja/requests/new?ticket_form_id=900000026183&uref=${userId}&email=${email}&new`
  );
};

export const redirectNewSMS = (userId, language_id) => {
  window.location.replace(
    `${API.BASE_URL}phoneNumber?uref=${userId}&language_id=${language_id}`
  );
};

export const redirectUnderScreeningFemaleUser = () => {
  window.location.replace('https://wi.bachelorapp.net/usc-female');
};

export const reopenValue = {
  TRIAL_MALE: 'trial_male',
  AWAITING_FOR_SCREENING_MALE: 'awaiting_for_screening_male',
  AWAITING_FOR_SCREENING_FEMALE: 'awaiting_for_screening_female'
};

export const Colors = {
  LINE: '#00B900',
  TWITTER: '#1DA1F2',
  MAIN_GOLD: '#C0A76E',
  MAIN_ORANGE: '#DE9C4E',
  WHITE: '#FFFFFF',
  BLACK: '#000000',
  BLACK_BAR: '#1A1A1A',
  LIGHT_DARK_GRAY: '#999999',
  GRAY: '#C4C4C4',
  GRAY_BAR: '#A3A3A3',
  PRIMARY_COLOR: '#C89C34',
  SILVER_RANK: '#A3A3A3',
  GOLD_RANK: '#C9C3AE',
  DIAMOND_RANK: '#B0C3D8',
  LIGHT_GRAY: '#C1C1C1'
};

export const rematchParticipantType = {
  NotParticipatedYet: 'not_participated_yet',
  AlreadyParticipated: 'already_participated', // return as error type from BE
  AfterRematchTime: 'after_rematch_time', // return as error type from BE
  NotAllowedUser: 'not_allowed_user' // return as error type from BE
};

// Facebook conversions API request version
export const fbApiVersion = 'v10.0';

export const FacebookPixelEventName = {
  PageView: 'PageView',
  CompleteRegistration: 'CompleteRegistration',
  SubmitApplication: 'SubmitApplication',
  StartTrial: 'StartTrial',
  Lead: 'Lead',
  Purchase: 'Purchase'
};

// Google Analytics id
export const gaID = 'UA-79446814-18';
export const dev_gaID = 'G-V4YTBT595L';

// Google tag manager id
export const gtmID = 'GTM-KV4SN9P';
export const dev_gtmID = 'GTM-WZXTPH44';

export const GoogleTagManagerConstants = {
  FormSubmit: 'formSubmit',
  CV2TriggerSMSVerificationCodeFirstTime:
    'CV2TriggerSMSVerificationCodeFirstTime',
  CV3TriggerRegistrationFormStep7FirstTime:
    'CV3TriggerRegistrationFormStep7FirstTime',
  CV4TriggerDatingConfirmationFirstTime:
    'CV4TriggerDatingConfirmationFirstTime',
  CV6TriggerLastStepFirstTime: 'CV6TriggerLastStepFirstTime'
};

export const SubReasonAboutDate = [
  '23',
  '24',
  '25',
  '11',
  '12',
  '9',
  '26',
  '13',
  '45'
];
export const SubReasonAboutDateNotMatch = ['37', '38'];
export const SubReasonAboutTimeNotMatch = ['41', '42', '43'];
export const SubReasonAboutCantUseApp = [
  '27',
  '28',
  '29',
  '30',
  '31',
  '32',
  '33',
  '34',
  '35'
];
export const SubReasonAboutService = [
  '5',
  '6',
  '11',
  '12',
  '13',
  '14',
  '15',
  '16',
  '26',
  '7'
];
export const ReasonAboutServiceForDeactivateCancelWithGoodPerson = 5;
export const OtherReasonAboutDateValues = '7';
export const AnotherOptionReasonAboutDateValues = '0';
export const OptionReasonAboutDateNotMatchValues = '1';
export const OptionReasonAboutTimeNotMatchValues = '99';
export const OptionReasonAboutCantUseAppValues = '100';
export const SubOtherReasonAboutDateValues = '13';
export const OtherReasonAboutServiceValues = '9';
export const OtherReasonAboutDontUseAppValues = '35';
export const OptionOnlyShowToMale = '34';
export const ReasonAboutDeactivateOrCancellation = {
  ReasonAboutDate: 'reason_about_date',
  ReasonAboutService: 'reason_about_service'
};

export const LinkDocPlanLight =
  'https://docs.google.com/forms/d/e/1FAIpQLSdHmZHQz2zmW7ki1vXrfrosb9bKg4cYyUrWOljFz0NxaZTYog/viewform';
export const LinkDocPlanNormal =
  'https://docs.google.com/forms/d/e/1FAIpQLScvBGirIB9upBh0hg-Xdw2_hY5Ot1Oc5Kq5XEaRZ3Z8CDJdkg/viewform';
export const LinkDocPlanPremium =
  'https://docs.google.com/forms/d/e/1FAIpQLSe9Az24wcnHUQdX_ru9KRMlauKFAmG1jPffBdpITpN5LQaBvA/viewform';
export const InvitationCode = {
  DEFAULT_CODE: 'BACHELOR_DATE'
};

// Education constant
export const Education = {
  Master: 1,
  Bachelor: 2,
  AssociateDiploma: 3,
  HighSchoolGraduation: 4,
  Other: 99
};

export const EducationGroup = {
  HighSchool: 1,
  AssociateDiploma: 2,
  LessFamousUni: 3,
  MediumStandardUni: 4,
  FamousUni: 5,
  MoreFamousUni: 6
};

export const BachelorMemberRate = {
  Average: 3,
  ThresholdToResetRate: 2.95
};

// Check email error by English
// TODO: don't need this constant if we have returned error by locale
export const EmailError = {
  InvalidEmail: 'Please enter a valid email address.',
  ExistedEmail: 'Email already exists.'
};

export const CANCEL_DEACTIVATE_ACCOUNT_ERROR = {
  NEED_TO_CANCEL_PARTICIPATION: 'need_to_cancel_participation',
  NEED_TO_PAY_INVOICE: 'need_to_pay_invoice',
  HOSTED_INVOICE_URL: 'hosted_invoice_url',
  NEED_TO_CANCEL_PARTICIPATION_FOR_REMATCH:
    'need_to_cancel_participation_for_rematch',
  HAS_AWAITING_MAIN_MATCH_SAMPLE_DATE_COMPLETED_FORM:
    'has_awaiting_parti_main_match_sample_date_and_completed_form',
  NEED_TO_CANCEL_DATING: 'need_to_cancel_dating',
  NEED_TO_GIVE_FEEDBACK: 'need_to_give_feedback',
  HAS_AWAITING_MAIN_MATCH_BUT_NOT_SAMPLE_DATE_OR_NOT_COMPLETED_FORM:
    'has_awaiting_parti_main_match_but_not_sample_date_or_not_completed_form',
  APPLYING_SUBSCRIPTION_BEING_PAUSED: 'applying_subscription_being_paused'
};

export const REGISTRATION_STEPS = {
  StepZero: 0,
  StepOne: 1,
  StepOnePointFive: 1.5,
  StepTwo: 2,
  StepThree: 3,
  StepFour: 4,
  StepFive: 5,
  StepSix: 6,
  StepSixPointFive: 6.5,
  StepSeven: 7,
  StepEight: 8,
  StepNine: 9,
  StepTen: 10,
  StepEleven: 11,
  StepTwelve: 12,
  StepThirteenth: 13,
  StepThirteenPointFive: 13.5,
  StepFourteenth: 14,
  StepFifteenth: 15,
  StepSixteenth: 16
};

export const NUMBER_QUESTION = {
  One: '1',
  Two: '2',
  Three: '3',
  Four: '4',
  Five: '5'
};

export const ANSWER = {
  A: 1,
  B: 2,
  C: 3,
  D: 4
};

export const IMAGE_MINE_TYPE = ['image/jpeg', 'image/png', 'image/jpg'];

export const NumberOnlyRegex = /^\d+$/;

export const DiscountType = {
  NO_DISCOUNT: 'no_discount',
  YOUNG: 'young',
  ISSUE_COUPON: 'issue_coupon',
  NO_DISCOUNT_TAX: 'no_discount_tax',
  ISSUE_B_COUPON: 'issue_b_coupon'
};

export const UserType = {
  NEW: 'new'
};

export const ShareTwitterUrl = 'https://twitter.com/intent/tweet?text=';
export const ShareLineUrl = 'https://line.me/R/share?text=';

export const SPECIAL_EVENT = {
  CHRISTMAS: 'christmas'
};

export const DateSlot = {
  LUNCH_TIME: 'lunch_time',
  EVENING_TIME: 'evening_time'
};

export const convertDateText = slot =>
  slot === DateSlot.LUNCH_TIME ? 'お昼' : '夕方';

export const SPECIAL_BTN_CAN_SELECT_SUB_ITEMS = {
  CHARACTER: 15,
  HOBBY: 13,
  VIEW_ON_LOVE: 16,
  VIEW_ON_MARRIAGE: 8
};
export const ErrorFeedBackCommitOption = {
  GOOD_POINT: 'good_point',
  IMPROVE_POINT: 'improve_point',
  SATISFACTION: 'satisfaction'
};

export const ratingPoint = {
  STAR_5: 16,
  STAR_4_5: 11,
  STAR_4: 7,
  STAR_3_5: 4,
  STAR_3: 1,
  STAR_2_5: -1,
  STAR_2: -8,
  STAR_1_5: -14,
  STAR_1: -18
};

export const NEW_PLAN_INFO_URL = {
  light: 'https://bulto.page.link/plan-change',
  normal: 'https://bulto.page.link/basic-plans',
  premium: 'https://bulto.page.link/premium-plans'
};

export const NEW_PREFERENCE_IDS = ['13', '8', '16'];

export const PAGE_NOT_SHOW_NEED_UPDATE_NEW_PREFERENCE_POPUP = [
  '/views-suggest',
  '/2nd-registration-form/step-4',
  '/2nd-registration-form/step-6',
  '/2nd-registration-form/step-6-5',
  '/2nd-registration-form/step-7',
  '/logout',
  '/login',
  '/sms-login',
  '/sms-verification-code',
  '/new-sms-login',
  '/new-sms-verification-code',
  '/cancel-subscription-pause-request'
];

export const FEEDBACK_FLEX_QUESTION_TYPE = {
  CHECKBOX: 'checkbox',
  RADIO: 'radio',
  TEXT_BOX: 'text_box'
};

export const CHANGE_TIME_SLOT_LUNCH_NOTICE = {
  popup_in_participation: 1,
  message_in_chat: 2,
  popup_in_match_profile: 3
};

export const PARK_AND_VIEO_POPUP_HYPER_LINK = {
  1: 'https://bachelorapp.zendesk.com/hc/ja/articles/4404707718681',
  2: 'https://bachelorapp.zendesk.com/hc/ja/articles/4404741225369'
};

export const AREAS_GUIDANCE_LINK =
  'https://docs.google.com/forms/d/e/1FAIpQLSf5RTs_f6fMD75nPDGRSkc41AkpXZ_w9Cgtx52zZJx-ucKYaw/viewform?usp=sf_link';

export const ERROR_TYPE = {
  USER_EMAIL_EMPTY: 'user_email_empty',
  PREFECTURE_DEACTIVATED: 'prefecture_deactivated',
  PARTICIPATED_LIMIT_FOR_TRIAL: 'participated_limit_for_trial',
  PARTICIPATED_LIMIT_FOR_SUBSCRIPTION: 'participated_limit_for_subscription',
  INVALID_REGISTRATION_STEP: 'invalid_registration_step',
  NEED_TO_CANCEL_PARTICIPATION: 'need_to_cancel_participation',
  NEED_TO_PAY_INVOICE: 'need_to_pay_invoice',
  HOSTED_INVOICE_URL: 'hosted_invoice_url',
  NEED_TO_CANCEL_PARTICIPATION_FOR_REMATCH:
    'need_to_cancel_participation_for_rematch',
  HAS_AWAITING_MAIN_MATCH_SAMPLE_DATE_COMPLETED_FORM:
    'has_awaiting_parti_main_match_sample_date_and_completed_form',
  NEED_TO_CANCEL_DATING: 'need_to_cancel_dating',
  NEED_TO_GIVE_FEEDBACK: 'need_to_give_feedback',
  HAS_AWAITING_MAIN_MATCH_BUT_NOT_SAMPLE_DATE_OR_NOT_COMPLETED_FORM:
    'has_awaiting_parti_main_match_but_not_sample_date_or_not_completed_form',
  TRAIL_USER_CAN_NOT_PURCHASE: 'trail_user_can_not_purchase',
  FAILED_TO_PAYMENT: 'failed_to_payment',
  DONT_HAVE_DATING_COMPLETE: 'dont_have_dating_complete',
  INVALID_DATING: 'invalid_dating',
  APPLYING_SUBSCRIPTION_BEING_PAUSED: 'applying_subscription_being_paused',
  PAYMENT_FAILED: 'payment_failed',
  NOT_ALLOWED_USER_EXTEND_TRIAL: 'not_allowed_user_extend_trial',
  NOT_ALLOWED_USER_CANCEL_TRIAL: 'not_allowed_user_cancel_trial',
  MULTI_REQUEST: 'multi_request',
  EXPIRED_DEFAULT_PAYMENT_CARD: 'expired_default_payment_card',
  PARTICIPATION_LIMITATION_FOR_AWAITING_FEMALE:
    'participation_limitation_for_awaiting_female'
};

export const NOT_SHOW_ERROR_POPUP_KEY = [ERROR_TYPE.PAYMENT_FAILED];

export const USER_CERTIFICATE_TYPE = {
  ANNUAL_INCOME: 1,
  UNMARRIED: 2,
  IDENTIFICATION: 3
};

export const USER_CERTIFICATE_TYPE_TEXT = {
  1: '年収証明書',
  2: '独身証明書',
  3: '本人確認証明書'
};

export const USER_CERTIFICATE_STATUS = {
  AWAITING: 1,
  APPROVED: 2,
  REJECTED: 9 //equal to no certificate
};

export const USER_CERTIFICATE_STATUS_TEXT = {
  1: '認証中',
  2: '認証済',
  9: '提出する'
};

export const DEFAULT_CERTIFICATE = {
  ANNUAL_INCOME: { type: 1, status: 9 },
  UNMARRIED: { type: 2, status: 9 },
  IDENTIFICATION: { type: 3, status: 9 }
};

export const USER_CERTIFICATE_DEATIL_LINK = {
  1: 'https://bachelorapp.zendesk.com/hc/ja/articles/7484979216025',
  2: 'https://bachelorapp.zendesk.com/hc/ja/articles/7484851685401'
};

export const INVALID_CARD_INFO_CODE = [
  'invalid_expiry_month',
  'invalid_expiry_year',
  'invalid_cvc',
  'incorrect_number',
  'incorrect_cvc'
];

export const NOTICE_POPUP = {
  NEW_CHAT_DESIGN: 4
};

export const INVALID_CARD_INFO = 'invalid_info';

export const SET_DEFAULT_CARD = 'set_default_card';

export const GROUP_ACTION_BUTTON = {
  CAFE_MESSAGE: 'CAFE_MESSAGE',
  VIDEO_MESSAGE: 'VIDEO_MESSAGE',
  CHANGE_TIME_MESSAGE: 'CHANGE_TIME_MESSAGE',
  CANCEL_DATING_BY_PARTNER: 'CANCEL_DATING_BY_PARTNER',
  CANCEL_DATING_POPUP: 'CANCEL_DATING_POPUP'
};

export const FEEDBACK_FOR_NEW_FEMALE_HYPER_LINK =
  'https://bachelorapp.zendesk.com/hc/ja/articles/4404741276697';

export const MAX_LENGTH_NAME_STEP_6 = 45;

export const COMMIT_OPTION_CAMPAIGN_HYPER_LINK =
  'https://www.wi.bachelorapp.net/commit-campaign-spring-2023';

export const CRUISE_DATING_CAMPAIGN_HYPER_LINK =
  'https://bulto.page.link/cruise-date-2023';

export const REFERRAL_CAMPAIGN_HYPER_LINK = '/invite-friends';

export const REFERRAL_CAMPAIGN_EXPIRE_DATE = '2023-02-28';

export const APPSTORE_DOWNLOAD_LINK =
  'https://apps.apple.com/jp/app/%E3%83%90%E3%83%81%E3%82%A7%E3%83%A9%E3%83%BC%E3%83%87%E3%83%BC%E3%83%88-%E6%81%8B%E6%B4%BB%E3%83%9E%E3%83%83%E3%83%81%E3%83%B3%E3%82%B0%E3%82%A2%E3%83%97%E3%83%AA-%E5%A9%9A%E6%B4%BB%E3%83%9E%E3%83%83%E3%83%81%E3%83%B3%E3%82%B0%E3%82%A2%E3%83%97%E3%83%AA/id1583004685';

export const GOOGLE_PLAY_DOWNLOAD_LINK =
  'https://play.google.com/store/apps/details?id=com.bachelordate.bachelordate';

export const HIDE_APPSTORE_DOWNLOAD_BANNER_PERIOD = 604800000;

export const ReasonForCancellation = {
  NoConvertientSchedule: 3,
  DontKnowPartner: 4,
  DontKnowDateFlow: 5,
  DontKnowDateSchedule: 6,
  DontKnowDateLocation: 7,
  PriorContactUnavailableAnxiety: 8,
  DontKnowFreePeriod: 9,
  BadHealthCondition: 10,
  HavingUrgentBusiness: 20,
  DissatisfactionToPartnersAge: 30,
  DissatisfactionToPartnersIncome: 31,
  DissatisfactionToPartnersJob: 32,
  DissatisfactionToPartnersHeight: 33,
  DissatisfactionToPartnersBodyShape: 34,
  DissatisfactionToPartnersEducation: 35,
  DissatisfactionToPartnersManner: 36,
  DissatisfactionToPartnersBRate: 37,
  DissatisfactionToPartnersFace: 38,
  DissatisfactionToPartnerOther: 39,
  ForCovidPrevention: 40,
  LateOrNoShow: 50,
  NoReturnMessage: 60,
  VideoDatingNotAccepted: 70,
  Other: 99,
  DissatisfiedWithMatchedPartner: 2
};

export const dontKnowPartnerChildrenValues = {
  DISSATISFACTION_TO_PARTNERS_AGE: 30,
  DISSATISFACTION_TO_PARTNERS_INCOME: 31,
  DISSATISFACTION_TO_PARTNERS_JOB: 32,
  DISSATISFACTION_TO_PARTNERS_HEIGHT: 33,
  DISSATISFACTION_TO_PARTNERS_BODY_SHAPE: 34,
  DISSATISFACTION_TO_PARTNERS_EDUCATION: 35,
  DISSATISFACTION_TO_PARTNERS_MANNER: 36,
  DISSATISFACTION_TO_PARTNERS_BRATE: 37,
  DISSATISFACTION_TO_PARTNERS_FACE: 38,
  DISSATISFACTION_TO_PARTNER_OTHER: 39
};

export const SUPPORT_EMAIL_SETTING = 'メールが届かない場合';
export const NEW_NEW_FLOW_FEMALE = '審査の流れ';
export const SUPPORT_BACHELOR_FE = 'よくある質問・ヘルプ';

export const EmailReservationTextLinks = [
  {
    text: SUPPORT_EMAIL_SETTING,
    link: SUPPORT_EMAIL_SETTING_LINK
  },
  {
    text: NEW_NEW_FLOW_FEMALE,
    link: NEW_NEW_FLOW_FEMALE_LINK
  },
  {
    text: SUPPORT_BACHELOR_FE,
    link: {
      1: SUPPORT_BACHELOR_MA_LINK,
      2: SUPPORT_BACHELOR_FE_LINK
    }
  }
];

export const datingDays = [
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
  'Sunday'
];

export const DATING_NOTICE_POPUP = {
  held_friday_dating: 5,
  heri_dating: 6,
  daily_dating: 7,
  every_day_dating: 8,
  black_user_welcome: 9,
  show_female_rank: 11,
  black_user_concierge: 12,
  summer_campaign: 13
};

export const DEFAULT_MAX_LENGTH_TEXT_INPUT = 1000;
export const MAX_LENGTH_TEXT_INPUT_FULL_WIDTH = 500;

export const UserProfiles = {
  ConditionChanges: 1,
  Invitation: 2,
  Help: 3
};

export const FEEDBACK_FORM_STEP = {
  FIRST_STEP: 1,
  SECOND_STEP: 2
};

export const Affiliate = {
  PV: {
    Male: 'ph8g3u2tq053',
    Female: 'ph891u9rqt0q'
  }
};

export const lengthOfSubReasonIsClicked = 1;

export const notRequestUserGlobalInfoPages = [
  '/cancel-subscription-pause-request'
];

export const PATTERN = {
  TYPE_A: 'a',
  TYPE_B: 'b'
};

export const heriDatingLPUrl = 'https://www.wi.bachelorapp.net/heri-gw-2023';

export const GW_HYPER_LINK = 'https://bulto.page.link/heri-gw-2023';

export const GW_EXPIRE_DATE = '2023-05-06';

export const PREFECTURE = {
  TOKYO: 1,
  KANSAI: 2,
  NAGOYA: 3,
  FUKUOKA: 5,
  HOKKAIDO: 6,
  SHIZUOKA: 7,
  MIYAGI: 9
};

export const CANCELATION_REASON_ABOUT_SERVICE_USE_OTHER_SERVICE_APP_VALUE = 80;

export const SKIP_INTERVAL = 3000;

export const INAPPLICABLE_BLOOD = {
  register_options_translations: [{ display_name: '未選択' }],
  value: ''
};

export const INAPPLICABLE_HOLIDAY = {
  label: '未選択',
  value: ''
};

export const INAPPLICABLE_BIRTH_PLACE = {
  name: '未選択',
  id: ''
};

export const GOOD_STAR_POINT = 3;

export const HALF_POINT = 0.5;

export const EVERY_DATING_LINK_MALE =
  'https://bul.to/bachelor-everyday-date-male-2023-09-30';
export const EVERY_DATING_LINK_FEMALE =
  'https://bul.to/bachelor-everyday-date-female-2023-09-30';

export const EVERY_DATING_EXPIRE_DATE = '2023/09/29';

export const LP_TERM_OF_SERVICE =
  'https://www.wi.bachelorapp.net/new-terms-of-service?pk_vid=8d4e48143db49e7d16818988268df978';
export const LP_PRIVACY =
  'https://www.wi.bachelorapp.net/privacy?pk_vid=8d4e48143db49e7d168189893566c29f';
export const LP_NEW_COST_PLAN = 'https://www.wi.bachelorapp.net/new-cost-plan';

export const ONE_LEFT_CONTRACT_TERM = 1;

export const ONE_CONTRACT_TERM = 1;

export const CRUISE_DATING_END_DATE = '2023-08-19';

export const completedDatingCountForNewFemalePopup = 1;

export const FEMALE_USER_RANK = {
  SILVER: 'Silver',
  GOLD: 'Gold',
  DIAMOND: 'Diamond',
  BLACK: 'Black'
};

export const USER_RANK_MAX_POINT = 5;

export const LNGMAP = {
  1: 'en',
  2: 'jp'
};

export const TEXT_LINK_FEED_BACK = 'https://fdl.bachelorapp.net/sr3Z';
export const LINE_LINK = {
  MALE_LINK: 'https://bulto.page.link/male_line',
  FEMALE_LINK: 'https://bulto.page.link/female_line'
};
export const ERROR_TYPE_VALIDATE_RE_REGISTER_USER = {
  USER_INVALID: 'user_invalid'
};

export const SUBSCRIPTION_PAUSE_ERROR_TYPE = {
  ACTIVE_SUBSCRIPTION_PAUSE: 'active_subscription_pause',
  EXPIRED_SUBSCRIPTION_PAUSE_CANCEL_PERIOD: 'expired_subscription_pause_cancel_period'
};

//particiaption item
export const SELECTED_DATING_ITEM_LIMIT = 3;

export const USER_VERSION = {
  ITEM_BETA: 'item_beta',
}

export const PARTICIPATION_ITEM_TYPE = {
  AGE: 'age',
  BODY_TYPE: 'body_type',
  FACE_PREFERENCES: 'face_preferences',
  DATING_AREA: 'dating_area',
  DATING_TIME: 'dating_time',
}

export const INITIAL_SUBMIT_DATA = [
  {
    type: PARTICIPATION_ITEM_TYPE.AGE,
    option: null
  },
  {
    type: PARTICIPATION_ITEM_TYPE.DATING_TIME,
    option: null
  },
  {
    type: PARTICIPATION_ITEM_TYPE.DATING_AREA,
    option: null
  },
  {
    type: PARTICIPATION_ITEM_TYPE.BODY_TYPE,
    option: null
  },
  {
    type: PARTICIPATION_ITEM_TYPE.FACE_PREFERENCES,
    option: null
  }
];

export const UserRegistrationVersion = {
  REGISTRATION_TEST_VERSION: 'test_ver_1'
};

export const DataEmptyOptionBottomSheetModal = [
  '※誤字・脱字がないことを今一度ご確認ください。',
  '※海外大学の場合は「海外大学」と入力してください。'
];
export const SAMPLE_CANCEL_POPUP_TITLE = {
  5: 'popup-date-flow-title',
  6: 'popup-date-schedule-title',
  7: 'popup-date-location-title',
  8: 'popup-meeting-chat-title',
  9: 'popup-free-period-title'
};

export const SAMPLE_CANCEL_POPUP_MESSAGE = {
  5: ['popup-date-flow-message-1', 'popup-date-flow-message-2'],
  6: ['popup-date-schedule-message-1', 'popup-date-schedule-message-2'],
  7: ['popup-date-location-message-1', 'popup-date-location-message-2'],
  8: ['popup-meeting-chat-message-1'],
  9: ['popup-free-period-message-1', 'popup-free-period-message-2']
};

export const SAMPLE_CANCEL_POPUP_LINK = {
  1: {
    5: 'https://www.wi.bachelorapp.net/dating-flow-native-male',
    6: 'https://bachelorapp.zendesk.com/hc/ja/articles/4411457352345-%E3%83%87%E3%83%BC%E3%83%88%E6%99%82%E9%96%93%E3%81%AB%E3%81%A4%E3%81%84%E3%81%A6',
    7: 'https://bachelorapp.zendesk.com/hc/ja/articles/4404707705497-%E3%81%A9%E3%81%86%E3%82%84%E3%81%A3%E3%81%A6%E5%BE%85%E3%81%A1%E5%90%88%E3%82%8F%E3%81%9B%E3%81%99%E3%82%8B%E3%81%AE',
    8: 'https://bachelorapp.zendesk.com/hc/ja/articles/7914531463449-%E5%BE%85%E3%81%A1%E5%90%88%E3%82%8F%E3%81%9B%E3%83%81%E3%83%A3%E3%83%83%E3%83%88%E3%81%AB%E3%81%A4%E3%81%84%E3%81%A6',
    9: 'https://bachelorapp.zendesk.com/hc/ja/articles/16776158100505-%E3%81%84%E3%81%A4%E3%81%8B%E3%82%89%E6%96%99%E9%87%91%E3%81%8C%E7%99%BA%E7%94%9F%E3%81%99%E3%82%8B%E3%81%AE'
  },
  2: {
    5: 'https://www.wi.bachelorapp.net/dating-flow-new',
    6: 'https://bachelorapp.zendesk.com/hc/ja/articles/4411451001753-%E3%83%87%E3%83%BC%E3%83%88%E6%99%82%E9%96%93%E3%81%AB%E3%81%A4%E3%81%84%E3%81%A6',
    7: 'https://bachelorapp.zendesk.com/hc/ja/articles/4404733276313-%E3%81%A9%E3%81%86%E3%82%84%E3%81%A3%E3%81%A6%E5%BE%85%E3%81%A1%E5%90%88%E3%82%8F%E3%81%9B%E3%81%99%E3%82%8B%E3%81%AE',
    8: 'https://bachelorapp.zendesk.com/hc/ja/articles/7914604011801-%E5%BE%85%E3%81%A1%E5%90%88%E3%82%8F%E3%81%9B%E3%83%81%E3%83%A3%E3%83%83%E3%83%88%E3%81%AB%E3%81%A4%E3%81%84%E3%81%A6'
  }
};

export const UNKNOWN_SCHOOL =
  'https://bachelorapp.zendesk.com/hc/ja/requests/new';
export const preferences = [
  { key: 'ageFrom', value: 'ageFromValue' },
  { key: 'ageTo', value: 'ageToValue' },
  { key: 'heightTo', value: 'heightToValue' },
  { key: 'heightFrom', value: 'heightFromValue' },
  { key: 'partnerBodyMin', value: 'bodyShapeFromValue' },
  { key: 'partnerBodyMax', value: 'bodyShapeToValue' },
  { key: 'smoking', value: 'smokingValue' },
  { key: 'drinking', value: 'drinkingValue' },
  { key: 'annualIncome', value: 'incomeValue' },
  { key: 'education', value: 'educationValue' },
  { key: 'divorce', value: 'divorceValue' },
  { key: 'appearancePriority', value: 'preferredImportanceOfLooksValue' }
];

export const doubleParameter = [
  'ageFrom',
  'ageTo',
  'heightTo',
  'heightFrom',
  'partnerBodyMin',
  'partnerBodyMax'
];

export const keyUpdateInformation = {
  SCHOOL: 'school'
};
